import React from 'react'
import Image from 'next/image'
import {ThoughtsConnnectedIcon} from './icons/thoughts-connected'
import {InstantAccessIcon} from './icons/instant-access'
import {IntegratedIcon} from './icons/integrated'
import {SecureIcon} from './icons/secure'

export const Product: React.FC = () => {
  return (
    <section>
      <div className="relative -top-20" id="product" />

      <div className="flex flex-col items-center md:space-y-10 space-y-10 px-5">
        <div
          className="w-full max-w-6xl rounded-3xl relative shadow-inner-white drop-shadow-md"
          style={{
            background:
              'radial-gradient(100% 100% at 50% 0%, #4E28B7 48.71%, #9726D5 100%)',
          }}
        >
          <div className="max-w-xl w-full md:pl-16 md:py-20 p-10 space-y-4">
            <h2 className="text-[#D2BDFF] font-semibold text-lg">Networked notetaking</h2>
            <h3 className="text-white font-bold text-4xl">Give your brain superpowers</h3>
            <p className="text-white text-opacity-75">
              Associate notes using backlinks, mirroring the way your mind works. Trace
              associations to give you instant recall.
            </p>

            <div className="pt-10 space-y-10">
              <div className="flex space-x-6 leading-none">
                <div className="flex-none w-10 h-10">
                  <ThoughtsConnnectedIcon />
                </div>

                <div className="space-y-3">
                  <h4 className="text-white font-semibold">Your thoughts connected</h4>
                  <p className="text-white text-opacity-75">
                    Use backlinking technology to link and recall notes.
                  </p>
                </div>
              </div>

              <div className="flex space-x-6 leading-none">
                <div className="flex-none w-10 h-10">
                  <InstantAccessIcon />
                </div>

                <div className="space-y-3">
                  <h4 className="text-white font-semibold">With instant access</h4>
                  <p className="text-white text-opacity-75 leading-tight">
                    Retrieve and search your notes instantly. Online or offline.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="absolute top-0 right-0 bottom-1/4 left-2/4 z-0 pointer-events-none">
            <Image
              src="/static/home/product-dots.png"
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          </div>

          <div
            data-aos="fade-right"
            className="absolute top-20 right-20 z-0 pointer-events-none hidden md:block"
          >
            <Image
              src="/static/home/product-connections.png"
              width={920 / 2}
              height={586 / 2}
            />
          </div>

          <div
            data-aos="fade-left"
            className="hidden md:block absolute top-80 right-32 z-0 pointer-events-none bg-gradient-to-b from-[#7653DA] to-[#6943D6] rounded-xl p-2 shadow-inner-white drop-shadow-lg"
          >
            <div className="left-[23px] top-3 h-12 absolute w-[2px] bg-[#F5E5FF66] bg-opacity-40" />

            <div className="space-y-3 text-sm leading-tight max-w-52">
              <div className="flex space-x-3">
                <div className="flex-none w-8 h-8 relative rounded-lg overflow-hidden drop-shadow">
                  <Image
                    src="/static/home/product-enrichment-avatar.png"
                    layout="fill"
                    objectFit="cover"
                  />
                </div>
                <div className="space-y-1">
                  <h4 className="text-[#ECE5FF] font-semibold">Alex MacCaw</h4>
                  <h5 className="text-white text-opacity-75">Chairman</h5>
                </div>
              </div>

              <div className="flex space-x-3">
                <div className="flex-none w-8 h-8 relative rounded-lg overflow-hidden drop-shadow">
                  <Image
                    src="/static/home/product-enrichment-company.png"
                    layout="fill"
                    objectFit="cover"
                  />
                </div>

                <div className="space-y-1">
                  <h4 className="text-[#ECE5FF] font-semibold">Clearbit</h4>
                  <h5 className="text-white text-opacity-75">
                    Know your customers. Win your market.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-full max-w-6xl md:min-h-xl rounded-3xl relative flex flex-col items-center overflow-hidden shadow-inner-white drop-shadow-md"
          style={{
            background:
              'radial-gradient(99.21% 99.21% at 50% 0%, #B23BA6 0%, #7D20CF 65.66%, #200B9F 100%)',
          }}
        >
          <div className="absolute inset-0 z-0 pointer-events-none">
            <Image
              src="/static/home/product-crm-lines.png"
              layout="fill"
              objectFit="cover"
              objectPosition="center"
            />
          </div>

          <div className="max-w-xl w-full px-16 py-20 space-y-4 text-center">
            <h2 className="text-[#FFCBFF] font-semibold text-lg">CRM</h2>
            <h3 className="text-white font-bold text-4xl">Improve your network</h3>
            <p className="text-white text-opacity-75">
              Easily pull up a running list of your meeting-notes and conversations with
              people.
            </p>
          </div>

          <div
            data-aos="fade-up"
            className="absolute top-72 hidden md:flex items-center px-10 transform-gpu"
          >
            <div className="max-w-5xl">
              <Image
                src="/static/home/product-crm-shot.png"
                width={2502 / 2}
                height={1322 / 2}
                layout="intrinsic"
                quality={100}
              />
            </div>
          </div>
        </div>

        <div
          className="w-full max-w-6xl rounded-3xl relative flex flex-col items-end overflow-hidden shadow-inner-white drop-shadow-md"
          style={{
            background:
              'radial-gradient(100% 100% at 50% 0%, #210CA0 48.71%, #266CD5 100%)',
          }}
        >
          <div className="absolute left-0 top-0 bottom-0 right-[50%] hidden md:flex flex-col items-center justify-center">
            <div className="absolute inset-0">
              <Image
                src="/static/home/product-research-background.png"
                layout="fill"
                objectFit="contain"
                objectPosition="center"
              />
            </div>

            <div data-aos="zoom-in-up" className="relative">
              <Image
                src="/static/home/product-research-search-ui.png"
                width={808 / 2}
                height={746 / 2}
                layout="intrinsic"
              />
            </div>
          </div>

          <div className="max-w-xl w-full md:pl-10 md:pr-16 md:py-20 p-10 space-y-4">
            <h2 className="text-[#B2BCFF] font-semibold text-lg">Research</h2>
            <h3 className="text-white font-bold text-4xl">Centralize your research</h3>
            <p className="text-white text-opacity-75">
              Pull in your Kindle highlights, web snippets, and notes into one place.
              Blazing-fast search at your fingertips.
            </p>

            <div className="pt-10 space-y-10">
              <div className="flex space-x-6 leading-tight">
                <div className="flex-none w-10 h-10">
                  <IntegratedIcon />
                </div>

                <div className="space-y-3">
                  <h4 className="text-white font-semibold">
                    Integrated with your devices
                  </h4>
                  <p className="text-white text-opacity-75">
                    We are where you are, mobile or desktop, online or offline. And we
                    keep everything in sync.
                  </p>
                </div>
              </div>

              <div className="flex space-x-6 leading-tight">
                <div className="flex-none w-10 h-10">
                  <SecureIcon />
                </div>

                <div className="space-y-3">
                  <h4 className="text-white font-semibold">Secure but open</h4>
                  <p className="text-white text-opacity-75">
                    End-to-end encryption keeps your notes secure. Our export and API
                    keeps your notes accessible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

import React from 'react'

export const InstantAccessIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={18}
      cy={18}
      r={11}
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.2}
      d="M4 7a1 1 0 011-1h13a1 1 0 110 2H5a1 1 0 01-1-1zM4 33a1 1 0 011-1h13a1 1 0 110 2H5a1 1 0 01-1-1z"
      fill="#fff"
    />
    <path
      d="M27.914 25.086L26.5 23.672 23.672 26.5l1.414 1.414 2.828-2.828zm2.672 8.328a2 2 0 102.828-2.828l-2.828 2.828zm-5.5-5.5l5.5 5.5 2.828-2.828-5.5-5.5-2.828 2.828z"
      fill="#fff"
    />
    <g opacity={0.2} fill="#fff">
      <path d="M18.998 25.938A1 1 0 0018 25h-3.876c1.148.637 2.47 1 3.876 1 .338 0 .671-.021.998-.062zM19 27.95A9.97 9.97 0 0110.859 25H5a1 1 0 00-1 1v2a1 1 0 001 1h13a1 1 0 001-1v-.05z" />
    </g>
    <path
      d="M11.07 22H18a1 1 0 001-1v-2a1 1 0 00-1-1h-8c0 1.457.39 2.823 1.07 4zM8.832 22A9.966 9.966 0 018 18H5a1 1 0 00-1 1v2a1 1 0 001 1h3.832z"
      fill="#fff"
    />
    <path
      opacity={0.2}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.582 15H18a1 1 0 001-1v-2a1 1 0 00-1-1h-3.876a8.03 8.03 0 00-3.543 4zm-2.124 0a9.995 9.995 0 012.4-4H5a1 1 0 00-1 1v2a1 1 0 001 1h3.458z"
      fill="#fff"
    />
  </svg>
)

import React from 'react'
import Image from 'next/image'
import {AppPreview} from './hero/app-preview'

export const Hero = () => {
  return (
    <section className="relative">
      <div className="absolute inset-0 pointer-events-none z-10">
        <Image
          src="/static/home/hero-bg.png"
          layout="fill"
          objectFit="cover"
          objectPosition="center"
        />
      </div>

      {/* Hero content */}
      <div className="pt-20 md:pt-40 relative z-10">
        {/* Section header */}
        <div className="text-center">
          <h1
            className="text-3xl md:text-6xl font-bold leading-tight tracking-tight"
            data-aos="zoom-y-out"
          >
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#C3D4FF] via-white to-[#CFBCFF]">
              Think better with Reflect
            </span>
          </h1>

          <div className="md:mt-5">
            <p
              className="text-lg md:text-xl leading-8 text-white text-opacity-70 mb-8"
              data-aos="zoom-y-out"
              data-aos-delay="150"
            >
              Keep track of your notes, books, and meetings.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center md:pt-20 relative">
        <div className="max-w-5xl relative z-10 px-5 transform-gpu">
          <div
            className="absolute inset-5 pointer-events-none z-0 rounded-md"
            style={{
              boxShadow:
                '0 -40px 200px rgba(0, 102, 255, 0.5), 0 40px 200px rgba(56, 243, 255, 0.5)',
            }}
          />

          <AppPreview />
        </div>

        <div className="absolute inset-0 pointer-events-none z-0 flex justify-center">
          <Image
            src="/static/home/hero-stars.png"
            width={1225}
            height={445}
            layout="intrinsic"
          />
        </div>
      </div>
    </section>
  )
}

import {ChevronRightIcon} from '@heroicons/react/solid'
import React from 'react'

export const Company: React.FC = () => {
  return (
    <div className="mt-10 md:mt-32 md:mb-10 flex flex-col items-center p-5">
      <div className="relative -top-32" id="company" />

      <div className="flex-none relative flex flex-col md:flex-row md:space-x-10 space-y-10 md:space-y-0 max-w-5xl">
        <div
          className="flex-1 w-full rounded-3xl bg-white bg-opacity-10 relative"
          style={{
            background:
              'linear-gradient(99deg, #402247 44.26%, #A742E6 69.14%, #262247 96.8%)',
          }}
        >
          <div className="absolute top-[1px] right-[1px] bottom-[1px] left-[1px] bg-home-back rounded-3xl" />
          <div className="p-12 pb-32 space-y-10 relative">
            <div className="space-y-5">
              <h3 className="text-[#AC38AB] font-semibold">About</h3>
              <h5 className="text-white text-3xl">
                We&apos;re an indie team dotted across the globe
              </h5>
              <p className="text-[#B1B2DD]">
                Our mission is to improve the way people think by making the world&apos;s
                best noteworked note-taking app.
              </p>
            </div>
          </div>

          <div className="absolute bottom-12 left-12">
            <a
              className="border border-[#9D40D8] text-[#C364FF] font-semibold text-sm rounded-lg px-4 py-2 cursor-pointer inline-flex space-x-2 items-center  hover:ring-purple-600 hover:ring-opacity-50 hover:ring transition duration-500"
              href="https://reflect.academy/our-values"
            >
              <span>See our values</span>
              <ChevronRightIcon width={18} height={18} />
            </a>
          </div>
        </div>

        <div
          className="flex-1 w-full rounded-3xl bg-white bg-opacity-10 relative"
          style={{
            background:
              'linear-gradient(104.28deg, #262247 1.24%, #395CC8 32.96%, #262247 68.22%)',
          }}
        >
          <div className="absolute top-[1px] right-[1px] bottom-[1px] left-[1px] bg-home-back rounded-3xl" />

          <div className="p-12 pb-32 space-y-10 relative">
            <div className="space-y-5">
              <h3 className="text-[#4A79FF] font-semibold">Academy</h3>
              <h5 className="text-white text-3xl">
                Learn how to take great notes at our academy
              </h5>
              <p className="text-[#B1B2DD]">
                Master the principles of note-taking in Reflect&apos;s free online
                Academy.
              </p>
            </div>
          </div>

          <div className="absolute bottom-12 left-12">
            <a
              className="border border-[#4A79FF] text-[#678FFF] font-semibold text-sm rounded-lg px-4 py-2 cursor-pointer space-x-2 inline-flex items-center hover:ring transition duration-500"
              href="http://reflect.academy"
            >
              <span>Take the course</span>
              <ChevronRightIcon width={18} height={18} />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

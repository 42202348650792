import React from 'react'

export const PublishingIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 17v18l5-7h9L18 17z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M20 5v2"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.5 7.01l-1 1.732M32.99 12.5l-1.732 1M35 20h-2M8.742 26.5l-1.732 1M7 20H5"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M8.742 13.5l-1.732-1M13.5 8.742l-1-1.732"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import React from 'react'

const PREVIEW_MOVIE_SRC =
  'https://cleanshot-cloud-fra.accelerator.net/media/15712/Wz2lWJffWyHHBGWDE8CW3Dqd3Dcv5C7wfDqxVjES.mp4'

export const AppPreview: React.FC = () => {
  return (
    <div className="relative rounded-lg overflow-hidden bg-white">
      <video
        preload="auto"
        autoPlay
        playsInline
        muted
        poster="/static/home/app-preview.png"
      >
        <source src={PREVIEW_MOVIE_SRC} type="video/mp4" />
      </video>
    </div>
  )
}

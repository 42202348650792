import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

export const Footer = () => {
  return (
    <footer className="px-6 py-16 space-y-3">
      <div className="flex flex-col items-center">
        <Image src="/static/home/footer-logo.png" width={768 / 2} height={480 / 2} />
      </div>
      <div className="max-w-6xl mx-auto text-center flex flex-col items-center space-y-5">
        <ul className="flex space-x-6 text-white text-sm">
          <li>
            <Link href="/discord">
              <a>Discord</a>
            </Link>
          </li>

          <li>
            <Link href="https://twitter.com/reflectnotes">
              <a>Twitter</a>
            </Link>
          </li>

          <li>
            <Link href="/downloads">
              <a>Downloads</a>
            </Link>
          </li>

          <li>
            <Link href="/terms">
              <a>Terms</a>
            </Link>
          </li>

          <li>
            <Link href="/privacy">
              <a>Privacy</a>
            </Link>
          </li>
        </ul>

        {/* Copyrights note */}
        <div className="text-sm text-[#B1B2DD]">
          Reflect App, LLC. All rights reserved.
        </div>
      </div>
    </footer>
  )
}

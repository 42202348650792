import React, {ReactElement} from 'react'
import {BrowserIcon} from './icons/browser'
import {CalendarIcon} from './icons/calendar'
import {EncryptedIcon} from './icons/encrypted'
import {IosAppIcon} from './icons/ios-app'
import {KindleImportIcon} from './icons/kindle-import'
import {NetworkedIcon} from './icons/networked'
import {PublishingIcon} from './icons/publishing'
import {SpeedIcon} from './icons/speed'

export const Features: React.FC = () => {
  return (
    <section className="flex justify-center items-center my-10 md:my-24 px-10">
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 gap-y-10">
        <FeatureItem
          title="Built for speed"
          description="Syncronized in real-time between all your devices."
          icon={<SpeedIcon />}
        />

        <FeatureItem
          title="Networked notes"
          description="Backlink notes to each other to form a graph of ideas."
          icon={<NetworkedIcon />}
        />

        <FeatureItem
          title="iOS app"
          description="Capture ideas on the go, online or offline."
          icon={<IosAppIcon />}
        />

        <FeatureItem
          title="End-to-end encryption"
          description="Super secure. Only you have access to your data."
          icon={<EncryptedIcon />}
        />

        <FeatureItem
          title="Calendar integration"
          description="Add people and meetings from Google Calendar."
          icon={<CalendarIcon />}
        />

        <FeatureItem
          title="Publishing"
          description="One-click publishing to the web."
          icon={<PublishingIcon />}
        />

        <FeatureItem
          title="Kindle import"
          description="Sync your book highlights and notes."
          icon={<KindleImportIcon />}
        />

        <FeatureItem
          title="In your browser"
          description="Capture snippets from Safari and Chrome."
          icon={<BrowserIcon />}
        />
      </div>
    </section>
  )
}

const FeatureItem: React.FC<{
  title: string
  description: string
  icon?: ReactElement
}> = ({title, description, icon}) => (
  <div className="max-w-56 space-y-5 flex flex-col items-center text-center">
    <div className="w-[40px] h-[40px]">{icon}</div>

    <div className="space-y-1">
      <h3 className="text-base text-white font-semibold">{title}</h3>
      <p className="text-base text-white text-opacity-70">{description}</p>
    </div>
  </div>
)

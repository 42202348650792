import React from 'react'

export const NetworkedIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={20}
      cy={20}
      r={13}
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M20 18a2 2 0 110 4 2 2 0 010-4z"
      fill="#080624"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 5a2 2 0 110 4 2 2 0 010-4zM20 31a2 2 0 110 4 2 2 0 010-4zM33 18a2 2 0 110 4 2 2 0 010-4zM7 18a2 2 0 110 4 2 2 0 010-4z"
      fill="#080624"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

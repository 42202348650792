import React from 'react'
import Image from 'next/image'
import Link from 'next/link'

export const Pricing: React.FC = () => {
  return (
    <div className="md:my-24 mt-10 relative flex flex-col items-center">
      <div className="relative -top-32" id="pricing" />

      <div className="absolute inset-0 flex items-center justify-center">
        <Image
          src="/static/home/pricing-bg.png"
          layout="intrinsic"
          width={2880 / 2}
          height={1694 / 2}
        />
      </div>

      <div className="text-center relative space-y-10">
        <div className="space-y-4 px-5">
          <h2 className="text-[#8A88FF] font-semibold">Get access</h2>
          <h3 className="font-bold md:text-3xl text-2xl">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#C3C1FF] via-white to-[#C3C1FF]">
              We like keeping things simple.
              <br /> One plan one price.
            </span>
          </h3>
        </div>

        <div className="flex flex-col md:flex-row items-center">
          <div className="flex-none flex items-center space-x-4">
            <span className="text-5xl text-purple-200">$15</span>
            <span className="text-[#B1B2DD]">/month</span>
          </div>

          <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-3 text-white mt-5 md:mt-0 md:ml-12">
            <div className="flex space-x-2 items-center">
              <TickIcon />
              <span>Networked note-taking</span>
            </div>

            <div className="flex space-x-2 items-center">
              <TickIcon />
              <span>End to end encryption</span>
            </div>

            <div className="flex space-x-2 items-center">
              <TickIcon />
              <span>Offline sync</span>
            </div>

            <div className="flex space-x-2 items-center">
              <TickIcon />
              <span>Kindle highlights sync</span>
            </div>

            <div className="flex space-x-2 items-center">
              <TickIcon />
              <span>Chrome and Safari web clipper</span>
            </div>

            <div className="flex space-x-2 items-center">
              <TickIcon />
              <span>iOS app</span>
            </div>
          </div>
        </div>

        <div className="">
          <Link href="/checkout?confirm=true">
            <a className="flex-none inline-block font-semibold text-white bg-gradient-to-b from-home-button-lg to-home-button-rg rounded-lg drop-shadow-lg px-4 py-2 shadow-inner-white transform active:translate-y-0.5 active:opacity-90 transition duration-100">
              Start your 14-day trial
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}

const TickIcon: React.FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="flex-none"
  >
    <circle cx="9" cy="9" r="9" fill="white" />
    <path
      d="M6 10L6.74542 11.3045C7.31053 12.2934 8.72832 12.3165 9.32528 11.3464L12 7"
      stroke="#181038"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

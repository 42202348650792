import React from 'react'

export const ThoughtsConnnectedIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x={15} y={15} width={20} height={20} rx={3} stroke="#fff" strokeWidth={2} />
    <path
      opacity={0.2}
      d="M14 30h-1a3 3 0 01-3-3V13a3 3 0 013-3h14a3 3 0 013 3v1"
      stroke="#fff"
      strokeWidth={2}
    />
    <path
      opacity={0.2}
      d="M9 25H8a3 3 0 01-3-3V8a3 3 0 013-3h14a3 3 0 013 3v1"
      stroke="#fff"
      strokeWidth={2}
    />
  </svg>
)

import React from 'react'

export const IosAppIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27 14V8a3 3 0 00-3-3H8a3 3 0 00-3 3v24a3 3 0 003 3h16a3 3 0 003-3v-7"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M21 21v-3h14v3H21z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M9 14v-3h14v3H9z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 5l-2 2h-4l-2-2"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import Head from 'next/head'
import React from 'react'

export const Meta: React.FC = () => (
  <Head>
    <meta
      name="description"
      content="Reflect keeps track of your thoughts, books, and meetings."
    />
    <meta
      name="og:description"
      content="Reflect keeps track of your thoughts, books, and meetings."
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:site" content="@reflectnotes" />
    <meta name="twitter:image" content="https://reflect.app/static/twitter-card.png" />
    <meta name="og:title" content="Reflect" />
    <meta name="og:image" content="https://reflect.app/static/twitter-card.png" />
    <meta name="og:url" content="https://reflect.app" />
    <meta name="apple-mobile-web-app-title" content="Reflect" />
    <link rel="apple-touch-icon" href="https://reflect.app/static/icons/1024x1024.png" />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="https://reflect.app/static/icons/180x180.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="512x512"
      href="https://reflect.app/static/icons/512x512.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="1024x1024"
      href="https://reflect.app/static/icons/1024x1024.png"
    />
  </Head>
)

import React from 'react'

export const KindleImportIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27 8V6a1 1 0 00-1-1H6a1 1 0 00-1 1v28a1 1 0 001 1h20a1 1 0 001-1V20"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M5 32a3 3 0 003 3h16a3 3 0 003-3v-1H5v1z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
    />
    <path
      d="M10 26v-2h12v2H10z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M10 20h12"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 14h10"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35 14l-4-3v6l4-3z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 33h2"
      stroke="#080624"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

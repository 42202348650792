import React from 'react'

export const BrowserIcon: React.FC = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 5H8a3 3 0 00-3 3v24a3 3 0 003 3h24a3 3 0 003-3V8a3 3 0 00-3-3z"
      stroke="#fff"
      strokeWidth={2}
    />
    <path
      d="M9 9h.01M12 9h.01M15 9h.01"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 26v-3h12v3H14z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M13 19h-1a2 2 0 00-2 2v1m17-3h1a2 2 0 012 2v1M13 30h-1a2 2 0 01-2-2v-1m17 3h1a2 2 0 002-2v-1"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

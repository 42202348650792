import React, {useState, useEffect} from 'react'
import Link from 'next/link'
import {SiteLogoImage} from 'components/shared-site/logo-image'
import clsx from 'clsx'

export const Header = () => {
  const [top, setTop] = useState(true)

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top])

  const scrollToAnchor = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const target = event.currentTarget.getAttribute('href')
    const targetElement = document.querySelector(target!)

    if (targetElement) {
      event.preventDefault()

      const top = targetElement.getBoundingClientRect().top + window.pageYOffset
      window.scrollTo({top, behavior: 'smooth'})
    }
  }

  return (
    <header
      className={clsx(
        'fixed w-full z-30 transition duration-300 ease-in-out transform-gpu',
        !top && 'bg-bluegray-900 bg-opacity-50 backdrop-blur-sm shadow-lg',
      )}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          <div className="flex-1 flex">
            <Link href="/home">
              <a className="flex items-center space-x-4" aria-label="Reflect">
                <div className="shadow-sm w-[38px] h-[38px]">
                  <SiteLogoImage width={38} height={38} />
                </div>

                <h1 className="text-xl font-extrabold tracking-wide">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#ECE4FF] to-white">
                    Reflect
                  </span>
                </h1>
              </a>
            </Link>
          </div>

          <div className="flex-1 justify-center space-x-5 hidden md:flex">
            <a
              href="#product"
              onClick={scrollToAnchor}
              className="text-sm font-semibold text-[#ECE5FF] hover:text-white transition duration-200"
            >
              Product
            </a>

            <a
              href="#pricing"
              onClick={scrollToAnchor}
              className="text-sm font-semibold text-[#ECE5FF] hover:text-white transition duration-200"
            >
              Pricing
            </a>

            <a
              href="#company"
              onClick={scrollToAnchor}
              className="text-sm font-semibold text-[#ECE5FF] hover:text-white transition duration-200"
            >
              Company
            </a>
          </div>

          <div className="flex-1 flex justify-end items-center space-x-6">
            <Link href="/auth">
              <a className="flex-none text-sm font-semibold text-[#ECE5FF] hover:text-white transition duration-200">
                Login
              </a>
            </Link>

            <Link href="/checkout?confirm=true">
              <a className="flex-none inline-block text-sm font-semibold text-white bg-gradient-to-b from-home-button-lg to-home-button-rg rounded-lg drop-shadow-lg px-4 py-2 shadow-inner-white transform active:translate-y-0.5 active:opacity-90 transition duration-100">
                Start Free Trial
              </a>
            </Link>
          </div>
        </div>
      </div>
    </header>
  )
}

import Image from 'next/image'
import React from 'react'

interface Props {
  width?: number
  height?: number
  className?: string
}

export const SiteLogoImage: React.FC<Props> = ({width = 32, height = 32, className}) => (
  <Image
    src="/static/icons/1024x1024.png"
    className={className}
    width={width}
    height={height}
    quality={100}
  />
)

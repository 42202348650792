import React from 'react'

export const SecureIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 13l14-8 14 8v5.858A16.306 16.306 0 0120 35v0A16.306 16.306 0 016 18.858V13z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M23 19v-2a3 3 0 00-3-3v0a3 3 0 00-3 3v2"
      stroke="#fff"
      strokeWidth={2}
    />
    <path
      opacity={0.4}
      d="M15 24a3 3 0 003 3h4a3 3 0 003-3v-4H15v4z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
    />
  </svg>
)

import React, {useEffect} from 'react'
import AOS from 'aos'
import {Header} from './shared/header'
import {Footer} from './home/footer'
import {Hero} from './home/hero'
import {Features} from './home/features'
import {GoogleAnalytics} from 'components/google-analytics'
import {Product} from './home/product'
import {SocialProof} from './home/social-proof'
import {Pricing} from './home/pricing'
import {Company} from './home/company'
import {Meta} from './shared/meta'

export const Home = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    })
  }, [])

  return (
    <>
      <GoogleAnalytics />
      <Meta />

      <div className="site flex flex-col min-h-screen overflow-hidden bg-home-back text-white font-sans">
        {/*  Site header */}
        <Header />

        {/*  Page content */}
        <main className="flex-grow">
          {/*  Page sections */}
          <Hero />

          <Features />

          <Product />

          <SocialProof />

          <Pricing />

          <Company />

          <Footer />
        </main>
      </div>
    </>
  )
}

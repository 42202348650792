import React from 'react'

export const CalendarIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32 8H8a3 3 0 00-3 3v21a3 3 0 003 3h24a3 3 0 003-3V11a3 3 0 00-3-3z"
      stroke="#fff"
      strokeWidth={2}
    />
    <path
      d="M5 11a3 3 0 013-3h24a3 3 0 013 3v3H5v-3z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
    />
    <path d="M11 5v4M29 5v4" stroke="#fff" strokeWidth={2} strokeLinecap="round" />
    <path
      d="M10 22v-2h2v2h-2zM10 29v-2h2v2h-2zM16 22v-2h2v2h-2zM16 29v-2h2v2h-2z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M22 22v-2h2v2h-2z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 29v-2h2v2h-2zM28 22v-2h2v2h-2z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import React from 'react'

export const IntegratedIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 13V8a3 3 0 013-3h24a3 3 0 013 3v18a3 3 0 01-3 3H21"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      opacity={0.4}
      d="M5 19a2 2 0 012-2h8a2 2 0 012 2v14a2 2 0 01-2 2H7a2 2 0 01-2-2V19z"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      d="M29 35h-8M35 25H21"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.4}
      d="M11.01 31H11"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 29l1 6"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

import React from 'react'

export const EncryptedIcon = () => (
  <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25 19v-3a5 5 0 00-5-5v0a5 5 0 00-5 5v3" stroke="#fff" strokeWidth={2} />
    <path
      d="M13 26a3 3 0 003 3h8a3 3 0 003-3v-6H13v6z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={2}
    />
    <path
      d="M11 35H9a4 4 0 01-4-4V9a4 4 0 014-4h22a4 4 0 014 4v22a4 4 0 01-4 4h-2"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <path
      opacity={0.4}
      d="M15 35h10"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

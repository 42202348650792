import React from 'react'
import Image from 'next/image'
import {Tweet} from 'components/tweet-rotator'
import tweetsJson from 'components/tweet-rotator/tweets.json'
import sampleSize from 'lodash/sampleSize'
import times from 'lodash/times'

export const SocialProof: React.FC = () => {
  const tweets = sampleSize(tweetsJson, 9)

  return (
    <div className="relative my-10 md:my-32 flex flex-col items-center">
      <div className="absolute inset-0 pointer-events-none z-0 flex items-center justify-center">
        <Image
          src="/static/home/social-proof-stars.png"
          width={2448}
          height={888}
          layout="intrinsic"
        />
      </div>

      <div
        data-aos="fade-up"
        className="absolute inset-0 pointer-events-none z-0 flex items-center justify-center"
      >
        <Image
          src="/static/home/social-proof-woosh.png"
          width={2260 / 2}
          height={888 / 2}
          layout="intrinsic"
        />
      </div>

      <div className="text-center space-y-5">
        <h2 className="md:text-5xl text-4xl font-bold">
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#C3D4FF] via-white to-[#70B2FF]">
            Loved by thinkers
          </span>
        </h2>
        <h3 className="text-[#B1B2DD] text-xl">
          Here&apos;s what people are saying about us
        </h3>
      </div>

      <div className="mt-10">
        <Grid columns={3}>
          {tweets.map((tweet: Tweet) => (
            <TweetItem key={tweet.url} tweet={tweet} />
          ))}
        </Grid>
      </div>
    </div>
  )
}

const Grid: React.FC<{columns: number}> = ({columns, children}) => {
  const items = React.Children.toArray(children)

  return (
    <div className="flex space-x-5 overflow-auto">
      {times(columns, (i) => (
        <div
          key={i}
          className="flex-none flex flex-row md:flex-col space-x-5 md:space-x-0 md:space-y-5"
        >
          {items.slice(i * columns, (i + 1) * columns)}
        </div>
      ))}
    </div>
  )
}

const TweetItem: React.FC<{tweet: Tweet}> = ({tweet}) => {
  return (
    <div className="bg-[#1A1738] drop-shadow-md shadow-inner-white rounded-lg p-4 flex relative bg-opacity-90 overflow-hidden flex-none max-w-sm">
      <div className="flex-none">
        <Image
          src={`/components/tweet-rotator/${tweet.handle}.jpg`}
          width={32}
          height={32}
          className="rounded-full"
        />
      </div>
      <div className="ml-4 space-y-2">
        <div className="flex space-x-1.5 font-semibold">
          <div className="text-[#DBE6FF]">{tweet.name}</div>
          <div className="text-[#9893E3]">@{tweet.handle}</div>
        </div>
        <div className="text-[#B1B2DD]">{tweet.body}</div>
      </div>
    </div>
  )
}
